import { results } from '@mdc/ui';

const { Alert } = results;

export const getInfectedArchiveAlert = (scanResults, extracted, t) => {
    const extractedDetectedBy = extracted?.reduce((prevEngine, currentEngine) =>
        prevEngine.detected_by < currentEngine.detected_by ? currentEngine : prevEngine
    )?.detected_by;

    const archiveDetectedBy = scanResults?.total_detected_avs;

    if (extractedDetectedBy > 0 && archiveDetectedBy > 0) {
        const message = t('The archive was detected by {{archiveNumberOfEngines}} engine(s) as infected, and contains files detected as infected by up to {{numberOfEngines}} engine(s)', { archiveNumberOfEngines: archiveDetectedBy, numberOfEngines: extractedDetectedBy });
        return <Alert message={message} type='warning' />;
    }

    return null;
};

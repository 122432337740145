import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { VoteButton } from '@mdc/ui';
import { useTranslation } from 'react-i18next';

import './VoteResults.scss';

const VoteResults = ({ icon, type, description, score, handleVote, alreadyVoted, disabledVote, voteType }) => {
    const { t, ready } = useTranslation();
    const processedScore = useMemo(() => {
        return Math.abs(score) > 999 ? Math.sign(score) * ((Math.abs(score) / 1000).toFixed(1)) + 'K' : Math.sign(score) * Math.abs(score);
    }, [score]);

    if (!ready) {
        return;
    }

    return <Row className='voteResults'>
        <Col xs={7} md={5} lg={5} className='aboutVote my-auto'>
            <div className='title'>
                <i className={`icon ${icon}`} />
                <h6>{type}</h6>
            </div>

            <p className='description'>
                {description}
            </p>
        </Col>
        <Col xs={5} md={7} lg={7} className='aboutVote my-auto'>
            <Row className='score text-right'>
                <Col xs={12} md={8} lg={8} className='scoreText text-right'>
                    {processedScore}
                </Col>

                <Col xs={12} md={4} lg={4} className='text-right'>
                    <VoteButton
                        handleVote={handleVote}
                        alreadyVoted={alreadyVoted}
                        disabled={disabledVote}
                        type={voteType}
                        buttonText={t('Vote')}
                    />
                </Col>
            </Row>
        </Col>
    </Row>;

};

VoteResults.defaultProps = {
    score: 0
};

VoteResults.propTypes = {
    icon: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    score: PropTypes.number,
    handleVote: PropTypes.func.isRequired,
    alreadyVoted: PropTypes.string,
    disabledVote: PropTypes.bool,
    voteType: PropTypes.string
};

export default VoteResults;

/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { results, PlainTable, InternalLink, PDFExport } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { ROUTES } from '@mdc/constants';
import MULTISCAN_DATA from '../multiscanning/MultiscanData';
import { dayjs, FileResultsService } from '@mdc/services';
import ColumnsData from './DeepCdrData';
import ExpandedRowComponent from './ExpandedRowComponent';
import { CustomContentLoader } from '../customContentLoader/CustomContentLoader';



import './DeepCDR.scss';

const { ScoreHeader, DownloadSanitizedButton } = results;
const DeepCDR = ({ results, sanitizedResult, downloadSanitized, resultsLoading, scanning, fileImage }) => {
    const { t, ready, i18n } = useTranslation();
    const fileInfo = results?.file_info?.display_name;
    const infoFile = results?.file_info;
    const scanResults = results?.scan_results;
    const extractedFiles = results?.extracted_files?.files_in_archive;
    const sanitizationResultsDetails = results?.process_info?.post_processing?.sanitization_details?.details;
    const dlpResults = results?.dlp_info?.hits;

    const sanitizedFileLink = sanitizedResult?.data_id ?
        <InternalLink to={`${ROUTES.results.href}${ROUTES.results.file}/${sanitizedResult?.data_id}/regular/overview`}>{t('Sanitized file report')}</InternalLink> : null;

    const [afterSanitizationResults, setAfterSanitizationResults] = useState(undefined);

    const downloadSanitizedVersionDom = sanitizedFileLink ? <span className='downloadSanitizedButtonBox'>
        {sanitizedFileLink}
    </span> :
        <span className='downloadSanitizedButtonBox'>
            <DownloadSanitizedButton sanitized={sanitizedResult} downloadSanitized={downloadSanitized} />
        </span>;

    const aboutDeepCdrDom = <p className='aboutDeepCdr'>{t('Reconstruction of files including the removal of potentially malicious code, actions, and macros')}</p>;

    useEffect(() => {
        if (!resultsLoading && !scanning && results?.sanitized && (results?.sanitized?.progress_percentage === 100 || (results?.sanitized?.progress_percentage === undefined && results?.process_info?.progress_percentage === 100))) {
            if (results.sanitized.data_id) {
                // If sanitized data_id is available, get scan result of the file after sanitized
                (async () => {
                    setAfterSanitizationResults(await FileResultsService.getAfterSanitizedResults(results.sanitized?.data_id));
                })();
            } else {
                // If sanitized data_id is NOT available, that mean the origin file is clean and doesn't need to scan
                // That means the file after sanitized have same scan result
                setAfterSanitizationResults(results);
            }
        } else {
            setAfterSanitizationResults(undefined);
            FileResultsService.cleanAfterSanitizedResults();
        }
    }, [resultsLoading, scanning, results]);

    const scoreMetascanDom = useMemo(() => {
        const scanResults = afterSanitizationResults?.scan_results;
        const scanProcessText = !scanResults ? t('Loading')
            : '';

        return <ScoreHeader
            category={t('After Data Sanitization')}
            score={scanResults?.total_detected_avs}
            info={scanResults?.total_detected_avs > 0 ? t('Threats detected') : t('No threats detected')}
            detection={scanResults?.total_detected_avs > 0 ? 'detected' : 'notDetected'}
            numberOfEngines={scanResults?.total_avs}
            scoreText={t('ENGINES')}
            isProcessing={!scanResults}
            processImgSrc={fileImage.processing.publicURL}
            processingText={scanProcessText}
        />;
    }, [afterSanitizationResults, t]);


    const deepCdrTableData = useMemo(() => {
        if (!results || !sanitizationResultsDetails || !Array.isArray(sanitizationResultsDetails)) {
            return [];
        }

        const details = sanitizationResultsDetails.map((item) => ({
            object_sha256: item.object_sha256,
            object_details: item.object_details,
        }));

        return sanitizationResultsDetails.map((item) => {
            const associatedDetails = details.find((detail) => {
                return detail.object_sha256 === item.object_sha256;
            });
            return {
                ...item,
                result: <i className='icon-checkmark-symbol' />,
                count: item.count || 0,
                details: associatedDetails ? associatedDetails.object_details : null,
            };
        });
    }, [results]);

    const createExpandedRows = (data) => {
        return data.map((item) => {
            const sha256Value = item.object_sha256;
            const detailValue = item.object_details || '';

            if (!sha256Value && !detailValue) {
                return item;
            }

            const expandableComponent = <ExpandedRowComponent sha256Value={sha256Value} detailValue={detailValue} />;

            return {
                ...item,
                meta: {
                    expandable: true,
                    data: {
                        custom: expandableComponent
                    }
                }
            };
        });
    };


    const expandedRows = createExpandedRows(deepCdrTableData);

    const multiscanTableData = useMemo(() => {
        const scanDetails = afterSanitizationResults?.scan_results?.scan_details;
        if (scanDetails) {
            dayjs.locale(i18n?.language?.split('-')[0] || 'en');
            return Object.keys(scanDetails).filter((key) => scanDetails[key]['scan_result_i'] === 1).reduce(
                (detectedThreats, key) =>
                    detectedThreats.concat({
                        result: (<span className='detected'><i className='icon icon-close' />{scanDetails[key]['threat_found']}</span>),
                        engine: (<span className='detected'>{key}</span>),
                        lastUpdate: dayjs(scanDetails[key]['def_time']).format('ll')
                    })
                , []);
        }
    }, [afterSanitizationResults, i18n]);

    const deepCdrTableDom = useMemo(() => {
        if (!deepCdrTableData || !results || resultsLoading || scanning) {
            return <CustomContentLoader />;
        }

        if (!deepCdrTableData?.length) {
            if (!multiscanTableData?.length) {
                return <p className='noCdr mb-0 w-100 text-center'>{t('Sanitized Successfully. No Sanitization Details Available')}</p>;
            }
            return <p className='noCdr mb-0 w-100 text-center'>{t('No Deep CDR action performed')}</p>;
        }

        return <PlainTable
            columnsData={ColumnsData(t)}
            data={expandedRows}
            isEntireRowExpandable={true}
            showExpandSymbol={true}
        />;

    }, [ColumnsData, deepCdrTableData, multiscanTableData, expandedRows, results, resultsLoading, scanning, t]);

    const multiscanTableDom = useMemo(() => {
        if (!multiscanTableData) {
            return;
        }

        return multiscanTableData.length ? <PlainTable
            columnsData={MULTISCAN_DATA}
            data={multiscanTableData}
        /> : undefined;

    }, [multiscanTableData]);

    if (!ready) {
        return null;
    }

    return <section className='deepCdr'>
        {infoFile && scanResults && fileInfo && results && (
            <Row>
                <Col>
                    <PDFExport
                        aditionalData={infoFile}
                        data={scanResults}
                        types={['pdf']}
                        fileName={fileInfo}
                        results={results}
                        extractedFiles={extractedFiles}
                        sanitizationResultsDetails={sanitizationResultsDetails}
                        dlpResults={dlpResults}
                    />
                </Col>
            </Row>
        )}
        <Row>
            <Col lg={4} md={4} xs={12}>
                {scoreMetascanDom}
                {aboutDeepCdrDom}
            </Col>
            <Col lg={8} md={8} xs={12}>
                {multiscanTableDom && <Row className='multiscanTable'>
                    {multiscanTableDom}
                </Row>}
                <Row className='deepCdrTable'>
                    {deepCdrTableDom}
                </Row>
                <Row className='downloadButton'>
                    {downloadSanitizedVersionDom}
                </Row>
            </Col>
        </Row>

    </section>;
};

DeepCDR.propTypes = {
    downloadSanitized: PropTypes.func.isRequired,
    sanitizedResult: PropTypes.object,
    results: PropTypes.object,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    fileImage: PropTypes.object.isRequired,
};

export default DeepCDR;

import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { results, PlainTable } from '@mdc/ui';
import { i18nUtility, metadefenderService, dayjs } from '@mdc/services';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import VULNERABILITIES_DATA from './VulnerabilitiesData';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { CustomContentLoader } from '../customContentLoader/CustomContentLoader';

import './Vulnerabilities.scss';

const { FileBasedVulnerability } = results;

const epochToDateTime = (epoch) => dayjs.utc(new Date(epoch * 1000).toISOString()).local().format('YYYY-MM-DD');

const LOADING_LIMIT = 10;

/* eslint-disable camelcase */
const Vulnerabilities = ({ results, vulnerabilityResults, reportVulnerability, reportVulnerabilityResult, isSignedIn }) => {
    const { ready, t } = useTranslation();

    const [hash, setHash] = useState('');
    const [tableValue, setTableValue] = useState(undefined);
    const [hasMoreData, setHasMoreData] = useState(false);

    useEffect(() => {
        if (results?.file_info?.sha1 && results.file_info.sha1 !== hash && vulnerabilityResults) {
            setHash(results.file_info.sha1);
        }
    }, [hash, results, vulnerabilityResults]);

    const loadData = async (offset) => {
        const response = await metadefenderService.vulnerabilities.getVulnerability({ hash, limit: LOADING_LIMIT, offset })[0];
        if (response?.data?.data) {
            const data = processRawData(response.data.data);
            setHasMoreData(data.length === LOADING_LIMIT);
            return data;
        }
        setHasMoreData(false);
        return [];
    };

    useEffect(() => {
        if (hash) {
            setTableValue(undefined);
            (async () => {
                const data = await loadData(0);
                setTableValue(data);
            })();
        }
    }, [hash]);

    const loadMoreCve = useMemo(() => async () => {
        const data = await loadData(tableValue.length);
        setTableValue([...tableValue, ...data]);
        return data.length;
    }, [tableValue]);

    const processRawData = (data) => {
        return data.map((cve) => {
            const id = cve.cve;
            const severity = `${cve.severity.toLowerCase()} [${cve.severity_index}]`;
            const score = cve.cvss_3_0?.impact_score ? cve.cvss_3_0.impact_score : '';
            const lastModified = epochToDateTime(cve.last_modified_epoch);

            const application = cve.product_info?.product?.name ? cve.product_info.product.name : '';

            const meta = {
                expandable: true,
                data: {
                    tableData: [
                        {
                            header: t('First published'),
                            value: epochToDateTime(cve.published_epoch)
                        },
                        {
                            header: t('Access vector'),
                            value: cve.cvss_2_0?.access_vector ? cve.cvss_2_0.access_vector : ''
                        },
                        {
                            header: t('Integrity impact'),
                            value: cve.cvss_2_0?.integrity_impact ? cve.cvss_2_0.integrity_impact : ''
                        },
                        {
                            header: t('Authentication'),
                            value: cve.cvss_2_0?.authentication ? cve.cvss_2_0.authentication : ''
                        },
                        {
                            header: t('Access complexity'),
                            value: cve.cvss_2_0?.access_complexity ? cve.cvss_2_0.access_complexity : ''
                        },
                        {
                            header: t('Confidentiality impact'),
                            value: cve.cvss_2_0?.confidentiality_impact ? cve.cvss_2_0.confidentiality_impact : ''
                        }
                    ],
                    description: <>
                        <p>{cve.description}</p>
                        <OutboundLink href={i18nUtility.rebuildUrlWithLangPrefix(`/vulnerabilities/${id}`)} target='_blank' rel='noopener noreferrer'>{t('Read CVE details')}</OutboundLink>
                    </>
                }
            };

            return { id, severity, score, lastModified, application, meta };
        });
    };

    const vulnerabilityDOM = useMemo(() => {
        if (vulnerabilityResults === undefined) {
            return <CustomContentLoader />;
        }

        let isFileTypeSupport = !!results.file_info?.file_type_category && ['D', 'G', 'T', 'M', 'Z'].indexOf(results.file_info.file_type_category.toUpperCase()) === -1;

        return <FileBasedVulnerability
            severityIndex={vulnerabilityResults ? vulnerabilityResults.severity_index : 0}
            severity={vulnerabilityResults ? vulnerabilityResults.severity : ''}
            reportVulnerabilityClick={reportVulnerability}
            reportVulnerabilityResult={reportVulnerabilityResult}
            isSignIn={isSignedIn}
            isFileTypeSupport={isFileTypeSupport}
            isColumnLayout={!!vulnerabilityResults}
        />;
    }, [vulnerabilityResults]);

    const tableDOM = useMemo(() => {
        if (!tableValue && !hash) {
            return;
        }

        if (!tableValue) {
            return <CustomContentLoader />;
        }

        return <PlainTable columnsData={VULNERABILITIES_DATA(t)} data={tableValue} hasLoadMore={hasMoreData} loadMoreAction={loadMoreCve} hasCollapseAll={true} />;
    }, [tableValue, hasMoreData, hash, loadMoreCve, t]);

    if (!ready) {
        return null;
    }

    // No vulnerability report
    if (vulnerabilityResults === false) {
        return <div className='vulnerabilitiesResultsPage'>
            <Row>
                <Col>
                    {vulnerabilityDOM}
                </Col>
            </Row>
        </div>;
    }

    return <div className='vulnerabilitiesResultsPage'>
        <Row>
            <Col lg={4} md={4} xs={12} className='vulnerabilitiesResultsColumn'>
                {vulnerabilityDOM}
            </Col>
            <Col lg={8} md={8} xs={12}>
                {tableDOM}
            </Col>
        </Row>
    </div>;
};

Vulnerabilities.propTypes = {
    results: PropTypes.object,
    vulnerabilityResults: PropTypes.any,
    reportVulnerability: PropTypes.func,
    reportVulnerabilityResult: PropTypes.string,
    isSignedIn: PropTypes.bool
};

export default Vulnerabilities;

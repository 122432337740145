import ContentLoader from 'react-content-loader';
import { UtilsFunctions } from '@mdc/services';

export const CustomContentLoader = () => {
    const minRows = 5;
    const maxRows = 12;
    const space = 10;
    const rowHeight = 20;
    const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);

    let keyCounter = 0;
    const generateWidth = (index) => ((index * 37) % 70) + 30;

    return (
        <ContentLoader
            className="contentLoader"
            speed={1}
            height={numRows * (rowHeight + space)}
            width="100%"
            style={{ paddingTop: '20px' }}
            primaryColor="#EDEEF3"
            secondaryColor="#f3f3f3"
        >
            {Array(numRows)
                .fill('')
                .map((_value, indexName) => (
                    <rect
                        key={keyCounter++}
                        x="0"
                        y={indexName * (rowHeight + space)}
                        rx="4"
                        ry="4"
                        width={`${generateWidth(indexName)}%`}
                        height={rowHeight}
                    />
                ))}
        </ContentLoader>
    );
};


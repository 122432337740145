import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import { HorizontalTable } from '@mdc/ui';
import { Col, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CustomContentLoader } from '../../customContentLoader/CustomContentLoader';


import './YaraRules.scss';

const convertToCode = (data) => {
    return <code dangerouslySetInnerHTML={{
        __html: JSON.stringify(data, null, 6)?.replace(/\n( *)/g, (match, p) => {
            return '<br>' + '&nbsp;'.repeat(p.length);
        })
    }} />;
};

const YaraRules = ({ sandboxResults, sandboxResources }) => {
    const { t, ready } = useTranslation();
    let yara;
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const handleExpandAll = () => {
        setIsAllExpanded(!isAllExpanded);
    };
    const getProcessedRawDataValue = (value) => {
        if (Array.isArray(value)) {
            return value.join(', ');
        }

        return value;
    };

    const tableColumns = [
        {
            header: t('Description'),
            accessor: 'description'
        },
        {
            header: t('Matches'),
            accessor: 'matchedStrings',
            parser: (data) => convertToCode(data)
        },
        {
            header: t('Author'),
            accessor: 'author'
        },
        {
            header: t('Date'),
            accessor: 'date'
        },
        {
            header: t('Reference'),
            accessor: 'reference'
        },
        {
            header: t('FileType'),
            accessor: 'filetype'
        },
        {
            header: t('Severity'),
            accessor: 'severity'
        },
        {
            header: t('Type'),
            accessor: 'type'
        },

    ];

    const processRawData = (data, meta) => meta.filter((row) => Object.hasOwnProperty.call(data, row.accessor) && (data[row.accessor]) || (data.metaData[row.accessor]))
        .map((row) => {
            let value;

            if (row.parser) {
                value = row.parser(data[row.accessor]);
            } else if (data[row.accessor]) {
                value = data[row.accessor];
            } else {
                value = data.metaData[row.accessor];
            }
            value = getProcessedRawDataValue(value);

            return { field: row.header, data: value, shouldSanitizeHTML: false };
        });


    const expandableRowsDom = useMemo(() => {
        if (!sandboxResources || !sandboxResults) {
            return;
        }

        yara = sandboxResources;

        return yara.yaraMatches.map((row) => {
            const plainTableDom = <HorizontalTable data={processRawData(row, tableColumns)} />;

            const verdict = row.verdict.verdict;
            const title = <div> {row.ruleName}
                <span className={`badge ${verdict}`}>
                    {verdict === 'INFORMATIONAL' ? 'NO THREAT' : verdict.replace('_', ' ')}
                </span>
            </div>;

            return <ExpandableRow
                key={row?.origin?.identifier}
                title={title}
                tableDom={plainTableDom}
                titleSize='sm'
                className='mt-5'
                isExpandedByDefault={isAllExpanded}
            />;
        });
    }, [sandboxResources, isAllExpanded]);

    const YaraDom = useMemo(() => {
        if (!expandableRowsDom) {
            return <CustomContentLoader />;
        }

        return <Row>
            <Col xs={12}>
                <div className='colapseAllButton' >
                    <p className='mt-4 mr-4' dangerouslySetInnerHTML={{ __html: t('The input file and all extracted files are checked against a broad range of YARA rules to identify and classify malware. This scan was performed using {{yaraNumberOfRules}} YARA rules containing a total of {{yaraNumberOfStrings}} strings.', { yaraNumberOfRules: yara?.yaraNumberOfRules || 0, yaraNumberOfStrings: yara?.yaraNumberOfStrings || 0 }) }} />
                    <Button variant='outline-secondary' onClick={handleExpandAll}>{isAllExpanded ? t('Collapse All') : t('Expand All')}</Button>
                </div>
                {expandableRowsDom}
            </Col>
        </Row>;
    }, [expandableRowsDom, isAllExpanded, yara, t]);

    if (!ready) {
        return null;
    }

    return (
        <section className='yara'>
            <p className='h6Mask mt-5'>{t('YARA Rules')}</p>
            {YaraDom}
        </section>

    );
};

YaraRules.propTypes = {
    sandboxResources: PropTypes.object,
    sandboxResults: PropTypes.object
};

export default YaraRules;


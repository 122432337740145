export default (t) => {
    const fields = [
        'description',
        'source',
        'action',
        'Class',
        'Path',
        'ControlType',
        'Element',
        'Visible',
        'Attribute',
        'Content',
        'OOXML',
        'SysKind',
        'CodePage',
        'LCID(suggestion)',
        'References',
        'CompilerConstants',
        'PCode',
        'Function',
        'Comments',
        'URI',
        'File',
        'IP',
        'Region',
        'Application_Visible',
        'Pid',
        'ParentPid',
        'Command',
        'Program',
    ];

    const parsers = ['Visible', 'OOXML', 'Application_Visible'];

    return fields.map((accessor) => ({
        header: t(accessor.replace(/_/g, ' ')),
        accessor,
        ...(parsers.includes(accessor) && { parser: (value) => (value ? t('true') : t('false')) }),
    }));
};
